import React from 'react';
import { graphql } from 'gatsby';
import moment from 'moment';
import GatsbyLink from '../components/GatsbyLink'
import Layout from '../components/Layout';
import Previewable from '../components/Previewable';
import SEO from '../components/SEO';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { PageHeader } from '../components/PageHeader';
import { RelatedPosts } from '../components/Posts/Related/RelatedPosts';
import { RenderShortcode } from '../components/RenderShortcode';
import { Image } from '../components/Image';
import Heading from '../components/Heading/Heading';
import './post.scss';
import { decodeEntities } from '../utils/helpers';

export const BlogPostTemplate = ({
  content,
  categories,
  title,
  featureImageUrl,
  date,
  author,
  blogSlug,
  showAuthor,
  location
}) => {
  
  return (
    <section className="single-post">
      <div className="outer">
        <div className="wrap">
          <div className="inner">
            <Breadcrumbs location={location} />
            {/* <h1 className="title">{title}</h1> 
            <Heading tag="h1" className="title">{title}</Heading>*/}

            <Heading tag="h1" className="title">{decodeEntities(title)}</Heading>
            {featureImageUrl && <Image src={featureImageUrl} alt="featured image" className="featured-image" position="relative" />}
            <div className="wrap">
              <RenderShortcode content={content?.replace(/\/wp-content\/uploads\//g,`${process.env.GATSBY_WP_ADDRESS}/wp-content/uploads/`)} />
            </div>
            {categories && categories.length ? (
              <div className="taxonomy">
                <h4>Categories:</h4>
                <ul className="taglist">
                  {categories.map((category, index) => (
                    <li key={`${category.slug}cat`}>
                      {category.slug && (
                        <GatsbyLink to={`/blog/category/${category.slug}/`}>
                          {`${category.name}${index < categories.length - 1 ? ', ' : ''}`}
                        </GatsbyLink>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  )
}

const BlogPost = ( props ) => {
  const { data, location } = props;
  const { wordpressPost: post, allWordpressPost: relatedPosts, site, siteSettings, wordpressWpSettings } = data;
  if (!post) return null;
  const { title, content, featured_image_url: featureImageUrl, yoast, categories, date, author } = post;
  const { blogSlug, siteUrl } = wordpressWpSettings;
  const canonicalAttr = `${siteUrl}/${blogSlug}/${data.wordpressPost.slug}/`;
  
  return (
    <Layout>
      <SEO
        title={`${yoast.metaTitle || decodeEntities(title)} | ${decodeEntities(wordpressWpSettings.title)}`}
        desc={yoast.metaDescription}
        canonical={canonicalAttr}
      />
      <BlogPostTemplate
        content={content}
        categories={categories}
        title={title}
        featureImageUrl={featureImageUrl}
        date={moment(date).calendar()}
        author={author}
        blogSlug={blogSlug}
        location={location}
        showAuthor={siteSettings.options.showAuthor}
      />
      {relatedPosts.edges && relatedPosts.edges.length > 0 && (
        <RelatedPosts posts={relatedPosts.edges} />
      )}
    </Layout>
  )
}

export default Previewable(BlogPost, 'post');

export const pageQuery = graphql`
  fragment PostFields on wordpress__POST {
    id
    slug
    content
    date(formatString: "DD/MM/YY h:mm:ss")
    title
  }
  query BlogPostByID($id: String!, $relatedCategories: [String!]) {
    wordpressWpSettings {
      title
      wordpressUrl
      blogSlug
      siteUrl
    }
    siteSettings: wordpressAcfOptions(options: {}) {
      options {
        showAuthor
      }
    },
    wordpressPost(id: { eq: $id }) {
      id
      title
      slug
      content
      excerpt
      wordpress_id
      date(formatString: "DD/MM/YY h:mm:ss")
      featured_image_url {
        source_url
      }
      categories {
        name
        slug
      }
      author {
        name
        slug
        avatar_urls {
          wordpress_48
        }
      },
      yoast {
        metaTitle: title,
        metaDescription: metadesc
      }
    }
    allWordpressPost(
      filter: {
        categories: { elemMatch: { slug: { in: $relatedCategories } } },
        id: { ne: $id }
      },
      limit: 3
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
  }
`
